import React from 'react'
import Loading from '@components/SuspenseLoading/Loading'
import {getProductsShowcaseProductsByIds_cached_products} from '@data/queries/components/__generated__/getProductsShowcaseProductsByIds_cached'
import isServerSide from '@helpers/misc/isServerSide'
import Container from '@packages/justo-parts/lib/components/Container'

import Product from './Product'
import useOutOfStockProducts from './useOutOfStockProducts'

import styles from './styles.module.css'

export default function ProductShowcase(props: {
  productsIds: string[]
  title: string
  ssrProducts: getProductsShowcaseProductsByIds_cached_products
}) {
  const {title, ssrProducts} = props

  const {products, loading} = useOutOfStockProducts(props)

  if ((isServerSide() && !ssrProducts) || loading || !products ) {
    console.warn(
      'Could not fetch showcased products while doing SSR. This should be fixed for an optimized experience'
    )
    return <Loading />
  }

  return (
    <div className={styles.container}>
      <Container>
        {title ? (
          <div className={styles.header}>
            {title ? <h1 className={styles.title}>{title}</h1> : null}
          </div>
        ) : null}
        <div className={styles.products}>
          {products.map(product => (
            <Product key={product._id} product={product} />
          ))}
        </div>
      </Container>
    </div>
  )
}
