import getClient from '@providers/apollo/getClient'
import gql from 'graphql-tag'

import {
  getProductsShowcaseProductsByIds_cached,
  getProductsShowcaseProductsByIds_cachedVariables
} from './__generated__/getProductsShowcaseProductsByIds_cached'

export const productsShowcaseQuery = gql`
  query getProductsShowcaseProductsByIds_cached($productsIds: [ID], $websiteId: ID, $menuId: ID) {
    products: productsByIds(productsIds: $productsIds, websiteId: $websiteId) {
      _id
      name
      altImg
      titleImg
      image {
        _id
        url: resizedURL(width: 1200, height: 1200)
      }
      description
      isOutOfStock
      availabilityAt(menuId: $menuId) {
        basePrice
        finalPrice
        available
        notAvailableMessage
      }
    }
  }
`

export default async function getProductsShowcase(
  websiteId: string,
  productsIds: string[],
  menuId: string
) {
  const {data} = await getClient().query<
    getProductsShowcaseProductsByIds_cached,
    getProductsShowcaseProductsByIds_cachedVariables
  >({
    query: productsShowcaseQuery,
    variables: {
      websiteId,
      productsIds,
      menuId
    }
  })

  return data.products
}
