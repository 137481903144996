import {useMemo} from 'react'
import {productsShowcaseQuery} from '@data/queries/components/products'
import {getOutOfStockProductsInListQuery} from '@data/queries/stock/getOutOfStockProductsInList'
import {getWebsiteMenuInformationQuery} from '@data/queries/website/websiteMenuInformation'
import isServerSide from '@helpers/misc/isServerSide'
import usePreferenceStoreId from '@hooks/usePreferencesStoreId'
import useInitialData from '@page-components/Layout/useInitialData'
import {useApolloQuery} from 'apollo-hooks'
import uniq from 'lodash/uniq'

export default function useSetOutOfStockProducts({productsIds, ssrProducts}) {
  const initialData = useInitialData()
  const {storeId = ''} = usePreferenceStoreId(initialData.website._id)
  const {products: queriedProducts} = useApolloQuery({
    query: productsShowcaseQuery,
    variables: {
      productsIds,
      websiteId: initialData.website._id,
      menuId: initialData.website.defaultMenuId
    },
    omit: isServerSide(),
    partial: true
  })
  const products = queriedProducts ? queriedProducts : ssrProducts

  const outStockProductsQuery = useApolloQuery({
    query: getOutOfStockProductsInListQuery,
    variables: {
      websiteId: initialData.website._id,
      productsIds: uniq(productsIds),
      storeId: storeId,
      deliverAt: null
    },
    partial: true,
    omit: productsIds.length === 0
  })

  const {website} = useApolloQuery({
    query: getWebsiteMenuInformationQuery,
    variables: {
      websiteId: initialData.website._id
    },
    partial: true,
    omit: isServerSide()
  })

  const hideNotAvailableProducts = website?.hideNotAvailableProducts

  const loading = !products

  const memoizedProducts = useMemo(() => {
    if (!products) return []
    const {outOfStockIds = []} = outStockProductsQuery
    const productList = products.filter(product => {
      if (hideNotAvailableProducts && outOfStockIds.includes(product._id)) return false
      if (!product.availabilityAt) return false
      if (product.availabilityAt.notAvailableMessage) return true
      if (!product.availabilityAt.available) return false

      return true
    })

    return productList
  }, [products, outStockProductsQuery, hideNotAvailableProducts])

  return {products: memoizedProducts, loading}
}
