import isServerSide from '@helpers/misc/isServerSide'
import {useApolloQuery as useQuery} from 'apollo-hooks'
import gql from 'graphql-tag'

import {
  getPreferencesStoreId,
  getPreferencesStoreIdVariables
} from './__generated__/getPreferencesStoreId'

export const preferencesStoreIdQuery = gql`
  query getPreferencesStoreId($websiteId: ID) {
    preferences: userPreferences(websiteId: $websiteId) {
      _id
      store {
        _id
      }
    }
  }
`

export default function usePreferenceStoreId(websiteId: string) {
  const result = useQuery<getPreferencesStoreId, getPreferencesStoreIdVariables>({
    query: preferencesStoreIdQuery,
    variables: {websiteId},
    partial: true,
    omit: isServerSide() || !websiteId
  })
  const {preferences, loading, observableQuery, networkStatus} = result

  return {storeId: preferences?.store?._id, loading}
}
