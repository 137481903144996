import gql from 'graphql-tag'

export const getOutOfStockProductsInListQuery = gql`
  query getOutOfStockProductsInList(
    $websiteId: ID
    $productsIds: [ID]
    $storeId: ID
    $deliverAt: Date
  ) {
    outOfStockIds: outOfStockProductsInList(
      websiteId: $websiteId
      productsIds: $productsIds
      storeId: $storeId
      deliverAt: $deliverAt
    )
  }
`
