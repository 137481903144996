import gql from 'graphql-tag'

export default gql`
  fragment websiteLayout on Website {
    layout
    menuLayout
    productsLayout
    checkoutLayout
    headerLayout
  }
`
