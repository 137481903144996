import layoutFragment from '@data/fragments/website/layoutFragment'
import getClient from '@providers/apollo/getClient'
import gql from 'graphql-tag'

import {
  getWebsiteMenuInformation_cached,
  getWebsiteMenuInformation_cachedVariables
} from './__generated__/getWebsiteMenuInformation_cached'

export const getWebsiteMenuInformationQuery = gql`
  query getWebsiteMenuInformation_cached($websiteId: ID) {
    website(websiteId: $websiteId) {
      _id
      name
      description
      deliveryTypes
      isDeactivatedByDebt
      minimumOrderPrice
      menuLayout
      productsLayout
      headerLayout
      defaultMenuId
      hasZoneMapAvailable
      isMultiBrand
      showStoreName
      activeGiftComponent
      hideNotAvailableProducts
      businessType
      categoryLayoutForMultiBrand
      categories {
        _id
        name
      }
      ...websiteLayout
    }

    design: sectionConfiguration(section: "design", websiteId: $websiteId) {
      _id
      design {
        productCardDesign
      }
    }
  }
  ${layoutFragment}
`

export default async function getWebsiteMenuInformation(websiteId?: string) {
  if (!websiteId) {
    return {website: null, design: null}
  }

  const {data} = await getClient().query<
    getWebsiteMenuInformation_cached,
    getWebsiteMenuInformation_cachedVariables
  >({
    query: getWebsiteMenuInformationQuery,
    variables: {
      websiteId
    }
  })

  return data
}
